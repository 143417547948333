import React from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from 'core-web/containers/LoadingContainer';
import ModuleContainer from 'core-web/containers/ModuleContainer';
import HeaderSeparator from 'theme/components/HeaderSeparator';
import CampaignModule from 'theme/components/campaigns/CampaignModule';

const getProductIds = modules => {
    return modules
        .filter(item => (item.campaign_layout || {}).selected_card === 'product')
        .map(product => product.campaign_layout.product_data.product);
};

const getArticleIds = modules => {
    return modules
        .filter(item => (item.campaign_layout || {}).selected_card === 'article')
        .map(item => item.campaign_layout.article_data.article);
};

const getCampaigns = (modules, isRightWide) =>
    modules
        ? modules.map((item, index) => {
              if (modules.length === 2) {
                  item.isWide = true;
              } else if (!isRightWide && index === 0) {
                  item.isWide = true;
              } else if (isRightWide && index === 2) {
                  item.isWide = true;
              }

              return item;
          })
        : [];

const Loading = props => <LoadingContainer {...props} success={rest => <CampaignModule {...rest} />} />;

const Campaign = ({ content }) => {
    const isRightWide = content.layout_select === 'right_wide';
    const { header_data: header, modules } = content;

    const productIds = getProductIds(modules);
    const articleIds = getArticleIds(modules);
    const campaigns = getCampaigns(modules, isRightWide);

    return campaigns.length > 0 ? (
        <>
            <HeaderSeparator data={header} />
            <ModuleContainer
                productIds={productIds}
                articleIds={articleIds}
                render={Loading}
                renderProps={{ isRightWide, campaigns }}
            />
        </>
    ) : null;
};

Campaign.propTypes = {
    content: PropTypes.object.isRequired,
};

export default Campaign;
