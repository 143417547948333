import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import { above } from 'core-web/util/mediaqueries.js';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import ArticleCard from 'theme/components/article/ArticleCard';
import CampaignCard from 'theme/components/campaigns/CampaignCard';
import ProductCard from 'theme/components/product/ProductCard';
import ProductGuide from 'theme/components/productguide/ProductGuide';

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CampaignRow = styled('div')`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${above.md} {
        flex-wrap: nowrap;
    }
`;

const excludedProps = ['isWide', 'setNoMargin'];
const Content = styled('div', { shouldForwardProp: prop => excludedProps.indexOf(prop) === -1 })`
    width: ${({ isWide }) => (isWide ? 'calc(100%)' : 'calc(50% - 2px)')};
    margin-bottom: 4px;

    ${above.md} {
        background-color: ${colors.white};
        width: ${({ isWide }) => (isWide ? 'calc(50% + 8px)' : '25%')};
        margin-bottom: 0;
    }
    ${above.lg} {
        width: ${({ isWide }) => (isWide ? 'calc(50% + 16px)' : '25%')};
    }

    &:not(:last-child) {
        margin-bottom: 4px;
        margin-right: ${({ isWide, setNoMargin }) => (isWide || setNoMargin ? 0 : '4px')};

        ${above.md} {
            margin-bottom: 0;
            margin-right: 8px;
        }
        ${above.lg} {
            margin-right: 16px;
        }
    }
`;

const CampaignModule = ({ customer, products, campaigns, isRightWide, articles }) => {
    const [allProducts, setAllProducts] = useState(products);

    useEffect(() => {
        const handlePriceLists = async products => {
            if (!products.length) {
                return;
            }

            const { getProductPrices, pricelistSeed } = customer;

            if (pricelistSeed) {
                const productsFromPricelist = await getProductPrices(products);

                if (productsFromPricelist?.length && Array.isArray(productsFromPricelist)) {
                    setAllProducts(productsFromPricelist);

                    return;
                }
            }

            setAllProducts(products);
        };

        handlePriceLists(products);
    }, [products, customer?.pricelistSeed]);

    return (
        <Column>
            <CampaignRow>
                {campaigns.map((item, index) =>
                    item.campaign_layout ? (
                        <Content setNoMargin={isRightWide && index === 1} isWide={item.isWide} key={index}>
                            {item.campaign_layout.selected_card === 'campaign' ? (
                                <CampaignCard isWide={item.isWide} data={item.campaign_layout.campaign_data} />
                            ) : item.campaign_layout.selected_card === 'product' && allProducts.length > 0 ? (
                                <ProductCard
                                    overrideImg={item?.campaign_layout?.product_data}
                                    isWide={item.isWide}
                                    data={allProducts.find(
                                        p => p.objectID === item.campaign_layout.product_data.product
                                    )}
                                    hideItems={['compare']}
                                    imageSizes={item.isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                />
                            ) : item.campaign_layout.selected_card === 'article' && articles.length > 0 ? (
                                <ArticleCard
                                    isWide={item.isWide}
                                    override={item.campaign_layout.article_data}
                                    data={articles.find(
                                        a => a.external_id === item.campaign_layout.article_data.article
                                    )}
                                />
                            ) : item.campaign_layout.selected_card === 'product_guide' ? (
                                <ProductGuide isWide={item.isWide} data={item.campaign_layout.product_guide_data} />
                            ) : null}
                        </Content>
                    ) : null
                )}
            </CampaignRow>
        </Column>
    );
};

CampaignModule.propTypes = {
    articles: PropTypes.array,
    campaigns: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    isRightWide: PropTypes.bool,
    products: PropTypes.array,
};

CampaignModule.defaultProps = {
    articles: [],
    isRightWide: false,
    products: [],
};

export default injectModel('customer')(CampaignModule);
